<template>
    <div class="container">
        <div class="sub-container">
            <div class="title">
                Обслуживаем по отработаной схеме
            </div>
            <div class="scheme">
                <div class="block">
                    <img src="../assets/icons/one.png" alt="">

                    <div class="sub-title">Консультация</div>
                    <div class="sub-text">Оставьте заявку и получите бесплатную консультацию по вашей проблеме</div>
                </div>
                <div class="block">
                    <div class="sub-title">Диагностика</div>
                    <div class="sub-text">Мастер приедет к вам в течении 20-40 минут и бесплатно проведет осмотр техники</div>
                    <img src="../assets/icons/two.png" alt="">

                </div>
                <div class="block">
                    <img src="../assets/icons/three.png" alt="">
                    <div class="sub-title">Ремонт</div>
                    <div class="sub-text">Мастер привезет с собой все необходимые детали в 82% случаев ремонт занимает не больше 1 часа</div>
                </div>
                <div class="block">
                    <div class="sub-title">Гарантия</div>
                    <div class="sub-text">После ремонта даем гарантию до 1 года</div>
                    <img src="../assets/icons/four.png" alt="">

                    
                </div>
                
            </div>
            <div class="button-container">
                <div class="phone-button">
                <button class="button" @click="scrollTo">заказать звонок</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Scheme',
        methods: {
        scrollTo(){
        document.getElementById('Form').scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        }
        }
    }
</script>

<style scoped>
    .container {
        width: 100%;
        height:  70vh;

        background: #181C14;
        display: flex;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .sub-container {
        width: 1600px;
    }

    .title {
        width: 100%;
        height: 100px;

        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 36px;
    }

    .scheme {
        display: flex;
        padding-bottom: 40px;
    }
    .block {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .sub-text {
        width: 270px;
        text-align: center;
        color: white;
    }

    .sub-title {
        font-size: 20px;
        color: #0EAA00;
        margin: 20px;
    }

    .mega-number {
        font-size: 120px;
        font-family: istok_bold;
        color: #0EAA00;
    }

    .button-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
    }

    .phone-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 8px;

    color: white;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;

    border-color: #0EAA00;
    border-width: 2px;

    text-transform: uppercase;

    background: #89a58b;
    outline: none;
    cursor: pointer;

    transform: scale(1.2);
    box-shadow: 0px 10px 10px rgb(214, 255, 210) inset, 0px -10px 10px rgb(56, 106, 53) inset;
    text-shadow: 0px 0px 10px black;
    transition: 0.15s;
  }

  .button:hover {
        text-shadow: 0px 0px 10px green;
    }

  @media (max-width: 800px) {

    .container {
        height: 1200px !important;
    }
    .sub-container {
        width: 100% !important;
        padding-top: 0px !important;
        padding-bottom: 40px !important;
        margin-top: -100px;
    }

    .scheme {
        flex-direction: column !important;
    }

    .block:nth-child(2){
        flex-direction: column-reverse !important;
    }
    .block:nth-child(4){
        flex-direction: column-reverse !important;
    }

    .title {
        text-align: center !important;
    }

    .button-container {
        display: none !important;
    }
  }

  @media (max-width: 1100px) {
    .sub-container {
        transform: scale(0.8);
    }
  }
</style>