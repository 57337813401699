<template>
    <div class="container" id="Form">
        <div class="sub-container">
            <div class="form">
                <div class="header">
                    <div class="title">Оставьте заявку</div>
                    <div class="sub-text">Мастер свяжется с вами в течении 10-15 минут</div>
                </div>
                <div class="inputs">

                    <div style="display: flex">
                        <img style="max-height: 150px; padding: 20px; margin-left: -20px;" src="../assets/icons/wtf.png" alt="">
                        <input 
                            v-bind:value="problem"
                            @input="problem = $event.target.value"
                            placeholder="Расскажите о проблеме" type="text" id="nameInput">
                    </div>

                    <div style="display: flex">
                        <img src="../assets/icons/user.svg" alt="">
                        <input 
                            v-bind:value="name"
                            @input="name = $event.target.value"
                            placeholder="Ваше имя" type="text" id="nameInput">
                    </div>

                    <div style="display: flex">
                        <img src="../assets/icons/phone.svg" style="width: 50px; margin-right: 12px;" alt="">
                        <input 
                            v-bind:value="phone" 
                            @input="phone = $event.target.value"
                            placeholder="+79001234567" type="tel" id="nameInput">
                    </div>
                </div>
                <div class="button-container">
                    <div class="phone-button">
                    <button @click="sendRequest(name, phone, problem)" class="button">заказать звонок</button>
                    </div>
                </div>
            </div>
            <div class="master">
                <img src="../assets/images/master.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        name: 'Form',
        data(){
            return {
                name: '',
                phone: '',
                problem: '',
            }
        },
        methods: {
            sendRequest(name, phone, problem){                
                if(name.length > 3 && phone.length > 5){
                    alert(`${name}, заявка отправлена, ожидайте звонка!`)
                    fetch(`https://рбт-новокузнецк.рф/client_info?name=${name}&phone=${phone}&problem=${problem}`, {
                        method: 'GET',
                    })

                    this.name = '';
                    this.phone = '';
                    this.problem = '';
                }

            }
        }
    }
</script>

<style scoped>
    .container {
        width: 100%;
        height:  60vh;

        background: #181C14;
        display: flex;
        justify-content: center;
    }

    .sub-container {
        width: 1600px;
        height: 100%;
        display: flex;
    }

    .form {
        background: #F4FFF3;
        height: 100%;
        width: 70%;

        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }

    .title {
        font-size: 56px;
        text-align: center;
    }

    .sub-text {
        font-size: 26px;
    }

    .master {
        width: 30%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: end !important;
    }

    .master img {
        width: 97%;
        margin-top: -8.7%;
        z-index: 1;
        object-fit:cover;
    }

    .inputs {
        display: flex;
        flex-direction: column;
    }

    input {
        font-size: 32px;
        margin: 20px;
        padding: 10px;

        border-top-right-radius: 16px;
        border-bottom-left-radius: 16px;;

        background-color: #F4FFF3;
    }

    .master::after {
        content: '';
        width: 160px;
        height: 200px;

        border-top: 1px solid #0EAA00;
        border-right: 1px solid #0EAA00;

        position: absolute;
        margin-left: -145px;
    }

    .phone-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 8px;

    color: white;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;

    border-color: #0EAA00;
    border-width: 2px;

    text-transform: uppercase;

    background: #89a58b;
    outline: none;
    cursor: pointer;

    transform: scale(1.2);
    box-shadow: 0px 10px 10px rgb(214, 255, 210) inset, 0px -10px 10px rgb(56, 106, 53) inset;
    transition: 0.15s;
  }

  .button:hover {
        text-shadow: 0px 0px 10px green;
    }   

  @media (max-width: 800px) {

    .container {
        height: 600px !important;
    }
    .sub-container {
        width: 100% !important;
    }

    .form {
        width: 100% !important;
    }
    .master {
        display: none !important;
    }

    .title {
        font-size: 10vw !important;
    }

    .sub-text {
        font-size: 5vw !important;
        text-align: center !important;

    }

    .button {
        margin-left: -15%;
    }

    .inputs {
        transform: scale(0.7) !important;
    }
  }
</style>