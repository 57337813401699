<template>
   <div class="container"
    :class="{
        'hidden-left': index < viewIndex-1,
        'left': index == viewIndex-1,
        'middle': index == viewIndex,
        'right': index == viewIndex+1,
        'hidden-right': index >= viewIndex+2,

    }"

   >
   </div>
</template>

<script>
    export default {

        name: 'CustomCard',

        data() {
            return {

            }
        },

        props: {
            cardImage: {
                type: String,
                default: "no-image",
            },

            index: {
                default: 0,
            },

            viewIndex: {
                default: 0,
            }
        }
    }
</script>

<style scoped>
    .container {
        max-width: 310px;
        max-height: 100%;

        position: absolute !important;


        background: white;

        border-radius: 12px;

        border-style: solid;
        border-width: 1px;
        transition: 0.3s;
    }

    .hidden-left {
        transform: translateX(-670px) scale(0.05);
        opacity: 0;
        pointer-events: none;
    }

    .hidden-right {
        transform: translateX(670px) scale(0.05);
        opacity: 0;
        pointer-events: none;

    }

    .left {
        transform: translateX(-370px) scale(0.85);
        opacity: 0.5;
        pointer-events: none;

    }

    .middle {
        transform: translateX(0px) scale(1);
        opacity: 1;
        pointer-events: none;


    }

    .right {
        transform: translateX(370px) scale(0.85);
        opacity: 0.5;
        pointer-events: none;

    }

    .image-container {
        width: 280px;
        height: 280px;

        overflow: hidden;

        position: absolute;

        top: 16px;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .image-container img {
        width: 100%;
        height: 100%;
    }

    .text-container {
        width: 80%;
        height: 130px;
        bottom: 0px;

        position: absolute;

        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;

        color: var(--text);

        font-size: 16px;
    }
</style>