<template>
    <div class="container">
        <div class="sub-socntainer">
            <div class="top">
                <div class="first-block">
                    <img src="../assets/images/new_logo.png" alt="">
                    <div class="phone-button">
                    <button class="button" @click="scrollTo">заказать звонок</button>
                    </div>
                </div>
                <div class="second-block">
                    <div class="phone-number">
                        <div class="mega-phone">+7-960-909-5511</div>
                        <div class="sub-text">Ежевдневно с 9:00 до 20:00</div>
                    </div>

                    <div class="links">
                        <div style="display: flex;">
                            <div class="link">
                                <img src="../assets/icons/whatsapp.png" alt="">
                                <a href="https://api.whatsapp.com/send?phone=79609095511">WhatsApp</a>
                            </div>

                            <div class="link" style="margin-left: 20px;">
                                <img src="../assets/icons/tg.png" alt="">
                                <a href="http://">Telegram</a>
                            </div>
                        </div>
                        <div class="link">
                            <img src="../assets/icons/mail.png" alt="">
                            <p>rbt.servis@list.ru</p>
                        </div>
                    </div>
                </div>
                <div class="third-block">
                    <p @click="scrollToElement('main')">Ремонт бытовой техники</p>
                    <p @click="scrollToElement('tech')">Виды ремонта</p>
                    <p @click="scrollToElement('brends')">Марки</p>
                </div>
                <div class="four-block">
                    <p @click="scrollToElement('whywe')">Преимущества</p>
                    <p @click="scrollToElement('scheme')">Схема работы</p>
                    <p @click="scrollToElement('reviews')">Отзывы</p>
                </div>
            </div>
            <div class="bottom">
                <div class="one">
                    © РБТ-СЕРВИС 2023. Все права защищены<br/>
                    Ремонт бытовой техники г. Новокузнецк, ул. Циолковского, д.48
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        methods: {
        scrollTo(){
        document.getElementById('Form').scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        },

        scrollToElement(elementId){
            document.getElementById(elementId).scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        }
        }
    }
</script>

<style scoped>
    .container {
        width: 100%;
        height:  50vh;

        background: #181C14;

        display: flex;
        justify-content: center;

        padding-top: 20px;
        padding-bottom: 20px;
    }

    .sub-socntainer {
        width: 1600px;
        color: white;
        display: flex;
        height: 100%;

        flex-direction: column;
    }

    .top {
        width: 100%;
        height: 80%;


        display: flex;
    }

    .first-block {
        height: 100%;
        width: 30%;


        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .first-block img {
        max-height: 60px

    }

    .third-block {
        height: 100%;
        width: 25%;

    }

    .four-block {
        height: 100%;
        width: 25%;

    }

    .second-block {
        height: 100%;
        width: 50%;

    }

    .first-block, .second-block, .third-block, .four-block {
        display: flex;
        justify-content: center;

    }

    .first-block img {

        margin-top: -100px;
        margin-left: 60px;
        height: 120px;
        margin-bottom: 60px;
    }

    .second-block {
        display: flex;
        align-items: center;
        padding-top: 40px;
        flex-direction: column;
    }

    .bottom {
        width: 100%;
        height: 20%;
        padding: 60px;
        text-align: center;
    }

    .phone-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 40px;
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        transform: scale(1.5);
        padding: 8px;

        color: white;
        border-top-left-radius: 16px;
        border-bottom-right-radius: 16px;

        border-color: #0EAA00;
        border-width: 2px;

        text-transform: uppercase;

        background: #89a58b;
        outline: none;
        cursor: pointer;

        box-shadow: 0px 10px 10px rgb(214, 255, 210) inset, 0px -10px 10px rgb(56, 106, 53) inset;
        text-shadow: 0px 0px 10px black;
        transition: 0.15s;

    }

    .button:hover {
        text-shadow: 0px 0px 10px green;
    }

  .phone-number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .mega-phone {
    font-size: 40px;
    color: #0EAA00;
  }

  .sub-text {
    color: white;
    font-size: 24px;
    margin-top: -16px;
  }

  .links {
    width: 100%;
    height: 100%;
    padding-left: 100px;
  }

  .link {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 100px;
    
  }

  .link img {
    height: 35px;
  }

  .link p, .link a {
    margin-left: 20px;
  }

  p, a {
    font-size: 21px;
    color: white;
    cursor: pointer;
  }

  .third-block, .four-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
  }

  .one, .two {
    width: 100%;
    font-size: 24px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1000px) {
    .container {
        height: 100vh !important;
    }

    .sub-socntainer {
        width: 100% !important;
        display: flex;
        justify-content: center !important;
    }

    .top {
        flex-direction: column !important;
    }

    .first-block {
        display: none !important;
    }

    .links {
        padding: 0;
        transform: scale(0.7);
        margin-left: -50px !important;
        min-width: 100% !important;

        flex-direction: column !important;
        justify-content: center !important;
        align-items: center!;
    }

    .links div {
        display: flex;
        justify-content: center;
        padding-left: 40px;
    }

    .link {
        margin: 0px !important;
    }

    .second-block, .third-block, .four-block {
        width: 100% !important;
    }
    .phone-number {
        width: 100% !important;
    }

    .one {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 16px !important;
        opacity: 0.6;
    }
  }
</style>