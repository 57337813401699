<template>
    <div class="container">
        <div class="sub-container">
            <div class="title">
                Ремонт бытовой техники всех марок
            </div>
            <div class="brends">
                <div class="brend" v-motion-pop-visible>
                    <img src="../assets/images/b1.png" alt="">
                </div>
                <div class="brend" v-motion-pop-visible>
                    <img src="../assets/images/b2.png" alt="">
                </div>
                <div class="brend" v-motion-pop-visible>
                    <img src="../assets/images/b3.png" alt="">
                </div>
                <div class="brend" v-motion-pop-visible>
                    <img src="../assets/images/b4.png" alt="">
                </div>
                <div class="brend" v-motion-pop-visible>
                    <img src="../assets/images/b5.png" alt="">
                </div>
                <div class="brend" v-motion-pop-visible>
                    <img src="../assets/images/b6.png" alt="">
                </div>
                <div class="brend" v-motion-pop-visible>
                    <img src="../assets/images/b7.png" alt="">
                </div>
                <div class="brend" v-motion-pop-visible>
                    <img src="../assets/images/b8.png" alt="">
                </div>
                <div class="brend" v-motion-pop-visible>
                    <img src="../assets/images/b9.png" alt="">
                </div>
                <div class="brend" v-motion-pop-visible>
                    <img src="../assets/images/b10.png" alt="">
                </div>
                <div class="brend" v-motion-pop-visible>
                    <img src="../assets/images/b11.png" alt="">
                </div>
                <div class="brend" v-motion-pop-visible>
                    <img src="../assets/images/b12.png" alt="">
                </div>
                <div class="brend" v-motion-pop-visible>
                    <img src="../assets/images/b13.png" alt="">
                </div>
                <div class="brend" v-motion-pop-visible>
                    <img src="../assets/images/b14.png" alt="">
                </div>
                <div class="brend" v-motion-pop-visible>
                    <img src="../assets/images/b15.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BrendSlide',
    }
</script>

<style scoped>
.container {
        width: 100%;
        height: 55vh;
        background-color:#181C14;

        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 16px;
    }

    .sub-container {
        width: 1600px;
        height: 100%;

        border-radius: 16px;
        border: solid 1px #0EAA00;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .title {
        width: 100%;
        font-size: 36px;
        padding: 20px;

        text-align: center;
        color: white;
        z-index: 2;
    }

    .absolute-title {
        font-size: 52px;
        color: #282828;
        position: absolute;
        left: 20%;
        height: 80px;
        width: 1200px;
        /* margin-top: 10px; */
        text-shadow: 2px 2px 0px #0EAA00, -2px -2px 0px #0EAA00, 2px -2px 0px #0EAA00, -2px 2px 0px #0EAA00;
        opacity: 0.1;
    }

    .brends {
        width: 100%;
        height: 100%;


        display: flex;
        justify-content: center;
        padding-right: 100px;
        flex-wrap: wrap;

        padding-left: 120px;
        padding-top: 10px;
    }

    .brend {
        display: flex;
        justify-content: center;
        align-items: center;

        transition: 0.3s;
    }

    .brend img {
        height: 65%;
        max-height: 80px;
        max-width: 100%;
        margin-left: 20px;
    }

    .brend:hover {
        transform: scale(1.1);
    }

    @media (max-width: 800px){

        .brends {
            width: 100% !important;
            padding: 0 !important;
        }
        .brend {
            width: 100px !important;
        }
    }
</style>