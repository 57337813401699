<template>
    <div class="container">
        <div class="sub-container">
            <div class="left-side">

                <div class="main-phone-title">
                    Ремонт бытовой техники в Новокузнецке
                </div>

                <div class="main-title" v-motion-pop>
                    <b>Ремонт</b>
                    <b>бытовой техники</b>
                    <b>в Новокузнецке</b>
                </div>

                <div class="services" v-motion-slide-left >
                    <div class="service">
                        <div class="service-icon"></div>
                        <div class="service-text">
                            <div class="service-title">20 минут</div>
                            <div class="service-sub">время выезда мастера</div>
                        </div>
                    </div>

                    <div class="service">
                        <div class="service-icon2"></div>
                        <div class="service-text">
                            <div class="service-title">Принимаем</div>
                            <div class="service-sub">чайники, микроволновки и прочую мелкую технику</div>
                        </div>
                    </div>

                    <div class="service">
                        <div class="service-icon3"></div>
                        <div class="service-text">
                            <div class="service-title">Менее 1 часа</div>
                            <div class="service-sub">среднее время ремонта</div>
                        </div>
                    </div>
                </div>

                <div class="phone-button">
                    <button @click="scrollTo" class="button">Вызвать мастера</button>
                </div>

                <!-- <div class="absolute-gradient"></div> -->
            </div>
            <div class="right-side" :style="{backgroundImage: `url(${require('@/assets/images/' + slider[pickedIndex].img)})`}">

                <div class="texts-container">
                    <div class="giant-text">
                        {{ slider[pickedIndex].title }}
                    </div>

                    <div class="sub-giant-text">
                        {{ slider[pickedIndex].subTitle }}
                    </div>
                </div>
               
                <div class="dots-container">
                    <div
                        v-for="(slide, index) in slider"
                        :class="{'picked-dot': index == pickedIndex}"
                        class="dot"
                        @click="turnSlide(index)"
                        >
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainSlide',
    methods: {
    scrollTo(){
      document.getElementById('Form').scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    },
    turnSlide(n){
        this.pickedIndex = n;
    }
    },

    data(){
        return {

            pickedIndex: 0,

            slider: [{
                title: 'Замена термостата',
                subTitle: 'от 900 р.',
                img: 'slider2.png'
            }, {
                title: 'Замена датчика уровня воды',
                subTitle: 'от 800 р.',
                img: 'slider3.png'
            }, {
                title: 'Ремонт конфорки',
                subTitle: 'от 1200 р.',
                img: 'slider4.png'
            }, 
        ]
        }
    },

    mounted() {
        setInterval(() => {
            this.pickedIndex++
            if(this.pickedIndex >= this.slider.length){
                this.pickedIndex = 0
            }
        }, 3500)
    },
}
</script>

<style scoped>
    .container {
        width: 100%;
        height: 80vh;

        background-color: #181C14;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sub-container {
        width: 1600px;
        height: 100%;


        display: flex;
    }

    .left-side {
        width: 60%;
        height: calc(100% - 40px);

        border-top: solid 1px #0EAA00;
        border-left: solid 1px #0EAA00;
        border-top-left-radius: 16px;

        margin-right: 70px;
        margin-bottom: 100px;

        box-sizing: border-box;

        position: relative;
    }

    .main-title {
        position: absolute;
        top: 25%;
        left: 60px;

        display: flex;
        flex-direction: column;
        color: white;

        font-size: 70px;

        line-height: 80px;
    }

    .services {
        width: 100%;
        height: 110px;

        position: absolute;
        top: 65%;

        padding-left: 60px;

        display: flex;
    }

    .service {
        width: 100%;
        height: 100%;

        display: flex;
    }

    .service-icon, .service-icon2, .service-icon3  {
        min-width: 90px;
        height: 110px;

        background-image: url(../assets/icons/service_icon1.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: ceter;
    }

    .service-icon2 {
        background-image: url(../assets/icons/service_icon2.png);
    }

    .service-icon3 {
        background-image: url(../assets/icons/service_icon3.png);
        height: 80px;
    }

    .service-text {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        color: white;
        padding-left: 20px;
    }

    .service-title {
        font-size: 25px;
    }

    .service-sub {
        width: 150px;
    }

    .main-title b {
        font-size: 70px;
    }

    .right-side {
        width: 40%;
        height: 100%;
        border-radius: 0px;
        border-top-right-radius: 16px;

        position: relative;

        background-size: contain;
        background-repeat: no-repeat;

        transition: 0.3s;
    }

    .texts-container {
        width: 100%;
        position: absolute;

        bottom: 100px;

        display: flex;
        justify-content: right;
        align-items: center;
        flex-direction: column;
    }

    .giant-text {
        font-size: 60px;
        font-family: istok_bold;

        color: white;


        text-align: center;
    }

    .sub-giant-text {
        font-family: istok_regular;

        font-size: 60px;
        text-align: right;

        color: white;
        width: 100%;
        padding-right: 24px;
        margin-top: -16px;
    }

    .dots-container {
        left: calc(50% - 75px);
        width: 150px;
        position: absolute;
        bottom: 20px;

        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .dot {
        width: 8px;
        height: 8px;

        background-color: #0EAA00;

        transform: rotate(45deg);

        cursor: pointer;
        transition: 0.15s;
    }

    .picked-dot {
        width: 14px !important;
        height: 14px !important;
        transform: rotate(45deg);
        background-color: #0EAA00;
        cursor: pointer;

    }

    .absolute-gradient {
        background: radial-gradient(circle, rgba(149,219,0,0.5130427170868348) 0%, rgba(255,255,255,0) 70%);
        width: 600px;
        height: 600px;
        position: absolute;
        bottom: 0px;
        right: 0px;
        pointer-events: none;
        transform: translateX(300px);
        transform: translateY(400px);
        z-index: 0;
    }

    .phone-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 40px;

    bottom: 20px;
    left: 50px;

    transform: scale(1.5);

    position: absolute;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 8px;

    color: #ffffff;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;

    border-color: #0EAA00;
    border-width: 2px;

    text-transform: uppercase;

    background: #89a58b;
    outline: none;
    cursor: pointer;

    transform: scale(1.2);

    box-shadow: 0px 10px 10px rgb(214, 255, 210) inset, 0px -10px 10px rgb(56, 106, 53) inset;
    text-shadow: 0px 0px 10px black;
    transition: 0.15s;
  }

  .button:hover {
        text-shadow: 0px 0px 10px green;
    }

    .main-phone-title {
        min-width: 100vw;
        padding-right: 10px;
        padding-left: 10px;
        display: none;
        padding-top: 50px;
    }

  @media (max-width: 800px) {
    .container {
        height: 600px !important;
    }
    .sub-container {
        width: 100% !important;
    }
    .main-title {
        width: 100% !important;
        text-align: center;
        padding: 0 !important;
        margin: 0 !important;
        top: 20px !important;
        display: none;
    }
    .main-title b {
        width: 100% !important;
        font-size: 30px !important;
        padding: 0px !important;
    }
    
    .main-phone-title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        width: 100% !important;

        color: white;
        font-size: 30px;
    }

    .right-side {
        display: none !important;
    }

    .services {
        display: flex;
        transform: scale(0.7) !important;
        flex-direction: column;
        gap: 40px;
        margin-top: -200px;
        min-width: 100vw !important;
    }

    .phone-button {
        font-size: 10vw !important;
        width: 100vw !important;

        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .left-side {
        border: none !important;

    }

    .button {
        transform: scale(0.8);
        width: 60vw !important;
        position: absolute !important;
        left: 12vw !important;
        right: 0 !important;
        
    }
  }

  @media (max-width: 1400px) {
    .right-side {
        display: none;
    }
    

    .main-title {
        margin-top: -140px;
    }
  }
</style>