<template>
  <router-view/>
</template>

<style>
  * {
    margin: 0;
    padding: 0;

    box-sizing: border-box;

    font-family: istok_regular;
    font-size: 18px;
  }

  @font-face {
    font-family: istok_regular;
    src: url(../src/assets/fonts/IstokWeb-Regular.ttf);
  }

  @font-face {
    font-family: istok_bold;
    src: url(../src/assets/fonts/IstokWeb-Bold.ttf);
  }

  @font-face {
    font-family: istok_italic;
    src: url(../src/assets/fonts/IstokWeb-Italic.ttf);
  }

  @font-face {
    font-family: istok_bolditalic;
    src: url(../src/assets/fonts/IstokWeb-BoldItalic.ttf);
  }

  html, body {
    width: 100%;
  }

  body {
    overflow: hidden;

  }
</style>
