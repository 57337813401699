<template>
    <Header/>
    <MainSlide id="main" />
    <TechSlide id="tech" />
    <BrendSlide id="brends" />
    <WhyWe id="whewe"/>
    <Scheme id="scheme"/>
    <CardCarousel id="reviews"/>
    <Form/>
    <Footer/>
</template>

<script>
import Header from '@/components/Header.vue'
import MainSlide from '@/components/MainSlide.vue'
import TechSlide from '@/components/TechSlide.vue'
import BrendSlide from '@/components/BrendSlide.vue'
import WhyWe from '@/components/WhyWe.vue'
import Scheme from '@/components/Scheme.vue'
import Footer from '@/components/Footer.vue'
import Form from '@/components/Form.vue'
import CardCarousel from '@/components/CardCarousel.vue'


export default {
  name: 'HomeView',
  components: {
    Header,
    MainSlide,
    TechSlide,
    BrendSlide,
    WhyWe,
    Scheme,
    Footer,
    Form,
    CardCarousel
    }
}
</script>

<style>

html, body {
  overflow-x: hidden;
  background-color: #282828;
}

</style>
