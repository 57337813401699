<template>
    <div class="container">
        <div class="sub-container">
            <div class="title">Виды ремонта бытовой техники</div>
            <div class="tech-container">

                <div class="card">
                    <img src="../assets/images/wm.png" alt="">
                    <div class="tech-sub">Стиральные машины</div>
                </div>
                <div class="card">
                    <img src="../assets/images/fr.png" alt="">
                    <div class="tech-sub">Холодильники</div>
                </div>
                <div class="card">
                    <img src="../assets/images/fu.png" alt="">
                    <div class="tech-sub">Электрические / Газовые печи</div>
                </div>
                <div class="card">
                    <img src="../assets/images/dw.png" alt="">
                    <div class="tech-sub">Посудомоечные машины</div>
                </div>
                <div class="card">
                    <img src="../assets/images/va.png" alt="">
                    <div class="tech-sub">Пылесосы</div>

                </div>
                <div class="card">
                    <img src="../assets/images/mc.png" alt="">
                    <div class="tech-sub">Мясорубки</div>

                </div>
                <div class="card">
                    <img src="../assets/images/mi.png" alt="">
                    <div class="tech-sub">Микроволновые печи</div>

                </div>
                <div class="card">
                    <img style="height: 70%; padding-bottom: 40px;" src="../assets/images/teapot.png" alt="">
                    <div class="tech-sub">Чайники</div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
 export default {
    name: 'TechSlide'
 }
</script>

<style scoped>
    .container {
        width: 100%;
        height: 90vh;
        background-color: #181C14;

        display: flex;
        justify-content: center;
        align-items: center;

    }

    .sub-container {
        width: 1600px;
        height: 100%;
        background: #F4FFF3;
        display: flex;
        flex-direction: column;
    }

    .title {
        width: 100%;
        font-size: 36px;
        padding: 20px;

        text-align: center;
    }

    .tech-container {
        width: 100%;
        height: 100%;


        display: flex;
        flex-wrap: wrap;

        padding-left: 120px;
        padding-top: 10px;
    }

    .card {
        width: 325px;
        height: 325px;

        position: relative;
        margin: 10px;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        transition: 0.3s;
        background: radial-gradient(circle, rgba(149,219,0,0.0) 0%, rgba(255,255,255,0) 100%);
    }

    .card:hover {
        background-color: white;
        background: radial-gradient(circle, rgba(149,219,0,0.5130427170868348) 0%, rgba(255,255,255,0) 100%);
        transform: scale(1.1);
    }

    .card:hover::after {
        content: '';
        position: absolute;
        border-top: solid 1px #0EAA00;
        border-left: solid 1px #0EAA00;
        border-top-left-radius: 16px;

        width: 200px;
        height: 200px;

        top: 0;
        left: 0;
    }

    .card:hover::before {
        content: '';
        position: absolute;
        border-bottom: solid 1px #0EAA00;
        border-right: solid 1px #0EAA00;

        border-bottom-right-radius: 16px;

        width: 200px;
        height: 200px;

        bottom: 0;
        right: 0;
    }

    @media (max-width:800px) {
        .container {
            display: none;
        }
    }

    @media (max-width:1600px) {
        .container {
            height: auto !important;
        }
    }
</style>