<template>
  <div class="container">
    <div class="sub-container">
      <div class="logo-container">

      </div>
      <div class="menu-container">
        <div class="menu-button" @click="scrollToElement('tech')">Виды ремонта</div>
        <div class="menu-button" @click="scrollToElement('scheme')">Схема работы</div>
        <div class="menu-button" @click="scrollToElement('reviews')">Отзывы</div>
      </div>
      <div class="phone-container">
        <div class="phone-number">
          <div class="sub-text">г. Новокузнецк, ул. Циолковского, д. 48</div>
          <div class="mega-phone">+7-960-909-5511</div>
          <div class="sub-text">Ежевдневно с 9:00 до 20:00</div>
        </div>
        <div class="phone-button">
          <button class="button" @click="scrollTo">заказать звонок</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  methods: {
    scrollTo(){
      document.getElementById('Form').scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    },

    scrollToElement(elementId){
      document.getElementById(elementId).scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    }
  }
}
</script>

<style scoped>
  .container {
    width: 100%;
    height: 86px;

    display: flex;
    justify-content: center; 

    background-color: #181C14;
  }

  .sub-container {
    width: 1600px;
    height: 100%;

    display: flex;
  }

  .logo-container {
    min-width: 400px;

    background-image: url(../assets/images/new_logo.png);
    background-size: contain;
    background-position: center;

    background-repeat: no-repeat;
    height: 100%;
  }

  .menu-container {
    width: 100%;
    height: 100%;


    display: flex;
    align-items: center;

    justify-content: space-evenly;

    color: white;
  }

  .menu-button {
    padding: 20px;

    cursor: pointer;
  }

  .phone-container {
    height: 100%;

    width: 100%;

    display: flex;

    justify-content: right;
    align-items: right;
    padding-right: 26px;
  }

  .phone-number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  .mega-phone {
    font-size: 24px;
    color: #0EAA00;
  }

  .sub-text {
    color: white;
    margin-top: -6px;
  }


  .phone-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 8px;

    color: white;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;

    border-color: #0EAA00;
    border-width: 2px;

    text-transform: uppercase;

    background: #89a58b;
    outline: none;
    cursor: pointer;

    transform: scale(1.3);
    transform: scale(1.2);
    box-shadow: 0px 10px 10px rgb(214, 255, 210) inset, 0px -10px 10px rgb(56, 106, 53) inset;
    transition: 0.15s;
  }

  .button:hover {
        text-shadow: 0px 0px 10px green;
    }

  @media (max-width: 800px) {
    .sub-container {
      width: 100% !important;
    }

    .logo-container {
      display: none;
    }

    .menu-container {
      display: none;
    }

    .phone-container {
      min-width: 100% !important;
    }

    .mega-phone {
      font-size: 40px !important;
    }

    .phone-button {
      display: none;
    }

    .phone-container {
      padding: 0 !important;
      margin: 0 !important;

      min-width: 100vw !important;

      display: flex;
      justify-content: center;
      padding-top: 28px !important;
    }
  }

  @media (max-width: 1600px) {
    .sub-text {
      font-size: 12px;
    }
  }

  @media (max-width: 1400px) {
    .logo-container {
      display: none;
    }
  }

</style>
