<template>
    <div class="container">
        <div class="sub-container">
            <div class="left-side"></div>
            <div class="right-side">
                <div class="header">
                    <div class="title">Почему именно мы?</div>

                </div>
                <div class="aboba">
                    <div class="new-service">
                        <div class="new-service-icon1"></div>
                        <div class="new-service-text">
                            <div class="new-service-title">1. Бесплатный приезд мастера. Бесплатная диагностика.</div>
                        </div>
                    </div>
                    <div class="new-service">
                        <div class="new-service-icon2"></div>
                        <div class="new-service-text">
                            <div class="new-service-title">2. Работа с новыми и устаревшими моделями.</div>
                        </div>
                    </div>
                    <div class="new-service">
                        <div class="new-service-icon3"></div>
                        <div class="new-service-text">
                            <div class="new-service-title">3. Высокое качество сервиса.</div>
                        </div>
                    </div>
                    <div class="new-service">
                        <div class="new-service-icon4"></div>
                        <div class="new-service-text">
                            <div class="new-service-title">4. Низкие цены. Гарантия. </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WhyWe',
    }
</script>

<style scoped>
    .container {
        width: 100%;
        height:  60vh;

        background: #181C14;

        display: flex;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .sub-container {
        width: 1600px;
        height: 100%;

        display: flex;
    }

    .left-side {
        height: 100%;
        min-width: 600px;

        background-image: url(../assets/images/slider1.png);
        background-size: cover;
    }

    .header {
        position: relative;
        height: 100px;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .right-side {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .aboba {
        width: 100%;
        height: 110px;

        top: 65%;

        padding-left: 60px;

        display: flex;
        flex-wrap: wrap;

        justify-content: center;
    }

    .new-service {
        width: 250px;
        height: 100px;

        display: flex;

        margin: 32px;
        margin-right: 150px;
    }

    .new-service-icon1, .new-service-icon2, .new-service-icon3, .new-service-icon4  {
        min-width: 90px;

        background-image: url(../assets/icons/service_icon1.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: ceter;
    }

    .new-service-icon1 { 
        background-image: url(../assets/icons/Union-1.png);
    }

    .new-service-icon2 { 
        background-image: url(../assets/icons/Union.png);
    }

    .new-service-icon3 { 
        background-image: url(../assets/icons/Group\ 83.png);
    }

    .new-service-icon4 { 
        background-image: url(../assets/icons/Union-2.png);
    }

    .new-service-text {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        color: white;
        padding-left: 40px;
    }

    .new-service-title {
        font-size: 25px;
        width: 250px;
    }

    .new-service-sub {
        width: 150px;
    }

    .title {
        width: 100%;
        font-size: 36px;
        padding: 20px;

        text-align: center;
        color: white;
        z-index: 2;
    }

    .absolute-title {
        font-size: 52px;
        color: #282828;
        position: absolute;
        left: 20%;
        height: 80px;
        width: 1200px;
        /* margin-top: 10px; */
        text-shadow: 2px 2px 0px #0EAA00, -2px -2px 0px #0EAA00, 2px -2px 0px #0EAA00, -2px 2px 0px #0EAA00;
        opacity: 0.1;
    }


    @media (max-width: 800px){
        .sub-container {
            width: 100% !important;
        }

        .left-side {
            display: none !important;
        }

        .new-service-title {
            font-size: 5vw !important;
            padding-right: 40px !important;
        }
    }

    @media (max-width:1600px) {

        .container {
            height: 500px;
        }
        .left-side {
            display: none;
        }
    }

    @media (max-width: 940px) {

        .container {
            height: 800px;
        }
        .left-side {
            display: none;
        }
    }
</style>