<template>
    <div class="container">
        <div class="title">
            <h2>Отзывы</h2>
        </div>
        <div class="description">
            <h3>Почитайте, что говорят наши клиенты</h3>
        </div>
        <div class="sub-container">
            <div class="arrow-left">
                <button :onClick="prevCard"></button>
            </div>
                <div class="cards-container">
                    <CustomCard 
                        v-for="(card, index) in cards"
                        :cardImage="card"
                        :index="index"
                        :viewIndex="viewIndex"
                        class="card"
                    />
                </div>
            <div class="arrow-right">
                <button :onClick="nextCard"></button>
            </div>
        </div>
    </div>
</template>

<script>

    import CustomCard from '@/components/CustomCard.vue'


    export default {

        components: {
            CustomCard
        },

        data(){
            return {
                viewIndex: 1,
                cards: ['otziv1.png', 'otziv2.png', 'otziv3.png']
            }
        },

        methods: {
            nextCard(){
                this.viewIndex += 1;
                if(this.viewIndex >= this.cards.length){
                    this.viewIndex = 0;
                }
            },

            prevCard(){
                this.viewIndex -= 1;
                if(this.viewIndex < 0){
                    this.viewIndex = this.cards.length-1;
                }
            }
        }
    }
</script>

<style scoped>
    .container {
        width: 100%;
        height: 70vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        padding-top: 44px;
        background: #181C14;
        
        
    }

    .sub-container {
        width: 1300px;
        height: 70%;

        display: flex;
        justify-content: center;
        
        /* align-items: center; */

    }

    .description {
        padding: 16px;
        padding-bottom: 28px;
    }

    .arrow-left, .arrow-right {
        height: 60%;
        /* border: solid 1px red; */
        width: 350px;
        opacity: 0.5;
        display: flex;
        align-items: center;
    }

    .arrow-left {
        left: 0;
        position: absolute;
        justify-content: right;
    }
    .arrow-right {
        right: 0;
        position: absolute;
        justify-content: left;
    }

    .cards-container {
        display: flex;
        position: relative !important;
        width: 100%;


        justify-content: center;
        align-items: center;
        pointer-events: none;
    }

    .arrow-left button, .arrow-right button {
        width: 30px;
        height: 100%;

        background: none;
        outline: none;
        border: none;

        cursor: pointer;

        background-image: url('../assets/icons/arrow.png');
        background-size: 50%;

        background-repeat: no-repeat;
        background-position: center;

        filter: invert(var(--invert));

        z-index: 10000;
    }

    .arrow-left button {
        transform: rotate(-180deg);
    }

    .arrow-right button {
        transform: rotate(0deg);
    }

    .arrow-left button:focus {
        transform: rotate(-180deg) scale(1);
    }

    .arrow-left button:active {
        transform: rotate(-180deg) scale(0.7);
    }

    .arrow-right button:focus {
        transform: rotate(0deg) scale(1);
    }

    .arrow-right button:active {
        transform: rotate(0deg) scale(0.7);
    }


    h2 {
        font-size: 48px;
        color: white;


        text-transform: uppercase;
    }

    h3 {
        font-size: 26px;
        font-weight: 100;
        color: white;
    }

    .card {
        background-size: contain;
        background-repeat: no-repeat;
        background-color: #F4FFF3;
    }
    .card:nth-child(1){
        background-image: url(../assets/images/otziv1.png);
    }

    .card:nth-child(2){
        background-image: url(../assets/images/otziv2.png);
    }

    .card:nth-child(3){
        background-image: url(../assets/images/otziv3.png);
    }

    .card:nth-child(4){
        background-image: url(../assets/images/otziv1.png);
    }

    .card:nth-child(5){
        background-image: url(../assets/images/otziv2.png);
    }

    .card:nth-child(6){
        background-image: url(../assets/images/otziv3.png);
    }

    @media (max-width: 800px) {
        .container {
            display: none !important;
        }
    }

    @media (max-width: 1000px) {
        .sub-container {
            transform: scale(0.8);
        }

        .arrow-left, .arrow-right {
            display: none;
        }
    }
</style>